import AuthLayout, { AuthProps, IAlertProps, IInputError } from '@/components/layout/auth-layout'
import GuestLayout from '@/components/layout/guest-layout'
import AppleStoreButton from '@/components/shared/apple-store-button'
import AuthInput from '@/components/shared/auth-input'
import GooglePlayButton from '@/components/shared/google-play-button'
import Loading from '@/components/shared/loading'
import { useAuthContext } from '@/context/auth-context'
import { useAuth } from '@/hooks/use-auth'
import { handleError } from '@/libraries/util'
import { LoadingButton } from '@mui/lab'
import { Button, Box as Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import { useRouter } from 'next/router'
import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'
import QRCode from 'react-qr-code'

function LoginPage() {
  const router = useRouter()
  const { signIn } = useAuthContext()
  const [busy, setBusy] = useState(false)
  const [alert, setAlert] = useState<IAlertProps | null>(null)
  const [usernameError, setUsernameError] = useState<IInputError | null>(null)
  const [isShowQrCode, setShowQrCode] = useState<boolean>(false)

  const [values, setValues] = useState<AuthProps>({
    email: '',
    password: '',
    confirmPassword: '',
    showPassword: false,
  })

  const [publicLink, setPublicLink] = useState<string | null>(null)

  useEffect(() => {
    if (router.query) {
      const origin = typeof window !== 'undefined' && window.location.origin ? window.location.origin : ''
      setPublicLink(`${origin}${router.asPath}`)

      const { email } = router.query
      if (email) {
        setShowQrCode(true)
        setValues({ ...values, email: email.toString() })
      }
    }
  }, [router.query])

  const handleChange = (prop: keyof AuthProps) => (event: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      setBusy(true)
      setAlert(null)
      setUsernameError(null)
      // const data = new FormData(event.currentTarget)
      // console.log({
      //   email: data.get('email'),
      //   password: data.get('password'),
      // })
      await signIn(values.email, values.password)
      const { redirect } = router.query
      if (redirect) {
        router.replace(redirect?.toString()).then()
      } else {
        router.replace('/dashboard/').then()
      }
    } catch (error) {
      handleError(error, (message) => {
        // setAlert({ type: AlertType.error, message })
        setUsernameError({ message })
      })
    } finally {
      setBusy(false)
    }
  }

  const onCreateAccountBtnClick = () => {
    router.push('/register/').then()
  }

  return (
    <GuestLayout title={'login page'}>
      <AuthLayout alert={alert} title={'Hi, Welcome!'} description={'Please sign in to continue.'}>
        <Box component="form" onSubmit={handleSubmit}>
          <AuthInput
            required
            id={'email'}
            name={'email'}
            value={values.email}
            placeholder={'Enter your email address here'}
            type={'email'}
            title={'Email'}
            helperText={''}
            error={usernameError}
            onChange={handleChange('email')}
            showInputAdornment={false}
            onHandleClickInputAdornment={null}
            onHandleMouseInputAdornment={null}
          />

          <Grid container direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 1.2 }}>
            <AuthInput
              required
              id={'password'}
              name={'password'}
              value={values.password}
              placeholder={'Enter your password here'}
              type={values.showPassword ? 'text' : 'password'}
              title={'Password'}
              helperText={''}
              onChange={handleChange('password')}
              showInputAdornment={values.showPassword}
              onHandleClickInputAdornment={handleClickShowPassword}
              onHandleMouseInputAdornment={handleMouseDownPassword}
            />
          </Grid>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 2 }}>
            <Link underline={'hover'} href={'/auth/forgot-password'}>
              <Typography
                component="small"
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  color: 'neutral.700',
                }}
              >
                Forgot password?
              </Typography>
            </Link>
          </Grid>

          <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
            {/* <Link my={3.5} underline="hover" href={'/auth/sign-in-with-qr'}>
              <Typography
                component="small"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '20px',
                  letterSpacing: '0em',
                  textAlign: 'center',
                  color: 'natural.900',
                }}
              >
                Sign in using QR Code
              </Typography>
            </Link> */}

            <Button type="submit" fullWidth variant="contained" disabled={busy} sx={{ height: 44 }}>
              Sign In
            </Button>
            <Typography component="small" marginY={1} color={'natural.900'}>
              Or
            </Typography>

            <LoadingButton
              loading={busy}
              onClick={() => onCreateAccountBtnClick()}
              fullWidth
              variant="outlined"
              sx={{ height: 44 }}
            >
              Create account
            </LoadingButton>
            {/* {isShowQrCode && ( */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {!isIOS && !isAndroid && (
                <Grid
                  maxWidth={300}
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  alignContent={'center'}
                  my={2}
                  border={1}
                  p={2}
                  borderRadius={4}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxSizing: 'border-box',
                    left: '0px',
                    top: '0px',
                    background: 'white',
                    border: '0.848px solid',
                    borderColor: 'neutral.200',
                    borderRadius: '5.936px',
                  }}
                >
                  {!(isAndroid || isIOS) && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxSizing: 'border-box',
                        width: '90px',
                        height: '90px',
                        left: '0px',
                        top: '1px',
                        background: 'white',
                        border: '0.848px solid',
                        borderColor: 'neutral.200',
                        borderRadius: '5.936px',
                      }}
                    >
                      <div style={{ height: '50', margin: '5 5 5 5', maxWidth: 75, width: 75 }}>
                        {publicLink && (
                          <QRCode
                            size={256}
                            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                            value={publicLink}
                            viewBox={'0 0 256 256'}
                          />
                        )}
                        {!publicLink && <Loading />}
                      </div>
                    </Box>
                  )}
                  <Typography marginY={3} variant={'caption'}>
                    Scan this QR code in your phone to download{' '}
                    <Typography variant={'caption'} color={'primary.dark'} sx={{ color: 'primary.dark' }}>
                      LiveLong Health
                    </Typography>{' '}
                    app
                  </Typography>
                  <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ mt: 0.5 }}>
                    <Stack>
                      <GooglePlayButton />
                      <AppleStoreButton />
                    </Stack>
                  </Grid>
                </Grid>
              )}
            </Box>
            {/* )} */}
          </Grid>
        </Box>
      </AuthLayout>
    </GuestLayout>
  )
}

export default useAuth({
  Component: LoginPage,
  middleware: 'guest',
  redirectIfAuthenticated: '/dashboard/',
})
